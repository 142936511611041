import { Controller } from "stimulus"
import axios from 'axios'

const validFileTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/webp',
  'application/pdf',
  'application/octet-stream'
]
const isValidFile = file => validFileTypes.includes(file.type)

export default class extends Controller {
  static targets = ['fileField']

  connect() {
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, (e) => this.preventDefaults(e))
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
      this.element.addEventListener(eventName, (e) => this.addHighlight())
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, (e) => this.removeHighlight())
    })

    this.element.addEventListener('drop', (e) => this.handleFileDrop(e))
    this.fileField.addEventListener('change', (e) => this.handleFileSelection(e))
  }

  handleFileDrop(e) {
    let transfer = e.dataTransfer
    let files = transfer.files
    let file = ([...files])[0]

    this.addSpinner()
    this.startFileUpload(file)
  }

  handleFileSelection(e) {
    let file = this.fileField.files[0]

    this.addSpinner()
    this.startFileUpload(file)
  }

  startFileUpload(file) {
    if(!isValidFile(file)) {
      this.removeSpinner()
      return
    }

    let formData = new FormData()
    formData.append('invoice[file]', file)

    axios.post(this.uploadUrl, formData)
      .then( response => this.redirectToInvoice(response) )
      .catch( () => this.removeSpinner() )
  }

  redirectToInvoice(response) {
    Turbolinks.visit(response.data)
  }

  addHighlight() {
    this.element.classList.add('is--active')
  }

  removeHighlight() {
    this.element.classList.remove('is--active')
  }

  addSpinner() {
    this.element.classList.add('is--uploading')
  }

  removeSpinner() {
    this.element.classList.remove('is--uploading')
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  get uploadUrl() {
    return this.data.get('url')
  }

  get fileField() {
    return this.fileFieldTarget
  }
}
