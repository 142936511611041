import { Controller } from "stimulus"

const COUNTRY_CODES_THAT_REQUIRE_AN_ISSUING_COURT = ["DE"]

export default class extends Controller {
  static targets = [
    "countryCodeInput",
    "registerCourtInput",
    "registerCourtLabel"
  ]

  connect() {
    this.updateCourtRequiredFlag()
  }

  updateCourtRequiredFlag() {
    if (!this.hasRegisterCourtInputTarget) { return }

    const isRequired = COUNTRY_CODES_THAT_REQUIRE_AN_ISSUING_COURT.includes(this.selectedCountryCode)

    this.registerCourtInputTarget.required = isRequired
    this.registerCourtLabelTarget.classList.toggle("is--required", isRequired)
  }

  get selectedCountryCode() {
    if (!this.hasCountryCodeInputTarget) { return }

    return this.countryCodeInputTarget.value.toUpperCase()
  }
}
