import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  initialize() {}

  connect() {
    this.performSearch()
  }

  performSearch() {
    axios.post(this.searchURL)
      .then( response => this.setSearchResults(response.data) )
      .catch( error => this.handleError(error) )
  }

  setSearchResults(data) {
    document.getElementById('eh-spinner-placeholder').outerHTML = data
  }

  handleError(error) {
    console.log(error)
  }

  get searchURL() {
    return this.data.get('url')
  }
}
