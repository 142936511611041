import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ['commentInput', 'list', 'error']

  connect() {
    this.element.addEventListener('ajax:success', (event) => this.addComment(event.detail[0]))
    this.element.addEventListener('ajax:error', (event, xhr) => this.showError(event.detail[0]))
  }

  addComment(htmlDocument) {
    this.errorTarget.classList.add('is--hidden')
    this.commentInputTarget.classList.remove('is-invalid')

    this.commentInputTarget.value = ''
    this.listTarget.prepend(htmlDocument.body.firstChild)
    this.listTarget.scrollTop = 0
  }

  showError(message) {
    this.errorTarget.classList.remove('is--hidden')
    this.commentInputTarget.classList.add('is-invalid')
    this.errorTarget.innerHTML = message
  }
}
