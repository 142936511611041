import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["display", "form"]
  static values = { showForm: Boolean }

  connect() {
    if (this.showFormValue) { this.showForm() }
  }

  showForm() {
    this.displayTarget.classList.add("d-none")
    this.formTarget.classList.remove("d-none")
  }
}
