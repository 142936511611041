import {Controller} from "stimulus"
import axios from 'axios'
import Chart from 'chart.js'
import {euro} from '../src/currency-helpers'

// When setting precision to 0, currency.js doesn't add thousands-separators anymore. So we format using decimals and then strip them from the string.
const roundedEuro = value => `${euro(value).format().split(',')[0]}€`

const lineColor = 'rgba(255, 255, 255, 0.5)'

export default class extends Controller {
  static targets = ['chartToggleIcon', 'chartContainer', 'canvas', 'monthToggle', 'yearToggle']

  initialize() {
    const currentDate = new Date
    this.year = currentDate.getFullYear()
    this.month = currentDate.getMonth()
    this.timeframe = 'month'
    this.chart = null

    Chart.Tooltip.positioners.custom = function(unused, position) {
      return { x: position.x, y: 170 };
    }
  }

  connect() {
    this.loadData()
  }

  toggleChart() {
    this.chartContainerTarget.classList.toggle('is--hidden')
    this.chartToggleIconTarget.classList.toggle('fa-angle-double-down')
    this.chartToggleIconTarget.classList.toggle('fa-angle-double-up')
  }

  toggleMonth() {
    const targetTimeFrame = 'month'

    this.yearToggleTarget.classList.remove('is--active')
    this.monthToggleTarget.classList.add('is--active')

    if (this.timeframe != targetTimeFrame) {
      this.timeframe = targetTimeFrame
      this.loadData()
    }
  }

  toggleYear() {
    const targetTimeFrame = 'year'

    this.monthToggleTarget.classList.remove('is--active')
    this.yearToggleTarget.classList.add('is--active')

    if (this.timeframe != targetTimeFrame) {
      this.timeframe = targetTimeFrame
      this.loadData()
    }
  }

  loadData() {
    axios.get(this.dataURL, {
      params: {
        timeframe: this.timeframe
      }
    })
    .then(response => this.drawChart(response.data))
    .catch(error => this.handleError(error))
  }

  drawChart(data) {
    let maxCashYAxis = null
    // We need to limit the maximum y axis of the chart for cash values, as very large sums would otherwise drown out smaller ones. If we simply set a max value for Chart.js, the value is used without regard for the actual values, so it might be too large itself (if everything is ~10.000 and the max is 100.000, the upper 90% of the chart will always be empty). So we use the maximum value we actually have, combined with an upper bound.
    // Edit: we only limit the y axis for the monthly view, not the yearly view. Because reasons.
    if (this.timeframe == 'month') {
      maxCashYAxis = Math.min(
        Math.max(...data.financed_amounts, ...data.financing_fee_amounts),
        100000
      )
    } else {
      maxCashYAxis = Math.max(...data.financed_amounts, ...data.financing_fee_amounts)
    }

    if (this.chart) { this.chart.destroy() }

    this.chart = new Chart(this.canvasTarget, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: this.labelForClients,
            yAxisID: 'clients',
            xAxisID: 'foo',
            data: data.clients,
            backgroundColor: '#7ED3D4',
            borderWidth: 0,
          }, {
            label: this.labelForFinancedAmounts,
            xAxisID: 'foo',
            yAxisID: 'revenue',
            stack: 'Stack 0',
            data: data.financed_amounts,
            backgroundColor: '#FB6C63',
            borderWidth: 0,
          }, {
            label: this.labelForFeeAmounts,
            xAxisID: 'foo',
            yAxisID: 'revenue',
            stack: 'Stack 0',
            data: data.financing_fee_amounts,
            backgroundColor: '#E2E786',
            borderWidth: 0,
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 5
          }
        },
        legend: {
          labels: {
            fontColor: 'rgba(255, 255, 255, 0.75)',
            fontSize: 16,
            padding: 30
          },
          position: 'bottom'
        },
        scales: {
          yAxes: [
            {
              id: 'clients',
              type: 'linear',
              position: 'left',
              ticks: {
                min: 0,
                beginAtZero: true,
                fontColor: 'rgba(255, 255, 255, 0.75)',
                maxTicksLimit: 5
              },
              gridLines: {
                display: false,
                color: '#828B99',
                lineWidth: 2
              },
            }, {
              id: 'revenue',
              type: 'linear',
              position: 'right',
              ticks: {
                min: 0,
                max: maxCashYAxis,
                beginAtZero: true,
                fontColor: 'rgba(255, 255, 255, 0.75)',
                maxTicksLimit: 5,
                callback: function (value, index, values) {
                  return roundedEuro(value)
                }
              },
              gridLines: {
                display: false,
                color: '#828B99',
                lineWidth: 2
              },
              stacked: true
            }
          ],
          xAxes: [
            {
              id: 'foo',
              ticks: {
                fontColor: 'rgba(255, 255, 255, 0.75)',
              },
              gridLines: {
                display: false,
                color: '#828B99',
                lineWidth: 2
              },

            }
          ]
        },
        tooltips: {
          backgroundColor: '#fff',
          titleFontColor: '#333',
          bodyFontColor: '#333',
          borderWidth: 0,
          position: 'custom',
          yAlign: 'top',
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ''

              if (label) { label += ': ' }

              // Index 0 is the "New clients" line.
              if (tooltipItem.datasetIndex > 0) {
                label += `${euro(tooltipItem.yLabel).format()}€`
              } else {
                label += tooltipItem.yLabel
              }

              return label
            },
          }
        }
      }
    })
  }

  handleError(error) {
    console.error(error)
  }

  get dataURL() { return this.data.get('url') }
  get labelForClients() { return this.data.get('labelClients') }
  get labelForFinancedAmounts() { return this.data.get('labelFinanced') }
  get labelForFeeAmounts() { return this.data.get('labelFees') }
}
