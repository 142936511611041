import { Controller } from "stimulus"
import CompanySearchSelectionController from "./company_search_selection_controller.js"
import axios from 'axios'

export default class extends CompanySearchSelectionController {
  submit() {
    const formData = { search_result_id: this.id }
    const headers  = { headers: {
      'Content-type': 'application/json',
      'Accept': 'application/javascript'
    }}

    axios.post(this.url, formData, headers)
      .then( response => this.parseResponse(response) )
      .catch( response => this.parseResponse(response) )
  }

  parseResponse(response) {
    eval(response.data)
  }

  get url() {
    return this.data.get('url')
  }

  get id() {
    return this.data.get('id')
  }
}
