import { Controller } from "stimulus"
import intlTelInput from "intl-tel-input"
// I18n
import de from "intl-tel-input/build/js/i18n/de"
import en from "intl-tel-input/build/js/i18n/en"
import fr from "intl-tel-input/build/js/i18n/fr"

const INPUT_HANDLER_DELAY_IN_MS = 250

export default class extends Controller {
  static targets = ["hiddenInput", "input"]
  static values = {
    countryCode: String,
    locale: String
  }

  connect() {
    this.handler = intlTelInput(this.inputTarget, {
      utilsScript: "/assets/intl-tel-input-utils.js",
      i18n: this.locale,
      initialCountry: this.countryCodeValue
    })

    // We might have arrived here with a prefilled number, so we need to set the hidden input once.
    this.enqueueInputCallback()
  }

  updateHiddenInput() {
    this.hiddenInputTarget.value = this.handler.getNumber()
  }

  enqueueInputCallback() {
    if (this.inputTimeout) { window.clearTimeout(this.inputTimeout) }
    this.inputTimeout = window.setTimeout(
      () => { this.updateHiddenInput() },
      INPUT_HANDLER_DELAY_IN_MS
    )
  }

  get locale() {
    switch (this.localeValue) {
      case "de": return de
      case "fr": return fr
      default: return en
    }
  }
}
