import { Controller } from "stimulus"
import rangeSlider from 'rangeslider-pure'
import { euro } from '../src/currency-helpers'

const feePercentages = [0.035, 0.045, 0.055]

export default class extends Controller {
  static targets = [
    'amount',
    'invoiceAmount',
    'remainingAmount',
    'fee',
    'feeAfterSkonto',
    'effectiveFee',
    'button',
    'skontoSlider',
    'invoiceSlider',
    'skontoPercentage',
    'skontoAmount'
  ]

  connect() {
    rangeSlider.create(this.skontoSliderTarget, {
      onSlide: (position, value) => { this.updateFees() },
    })

    rangeSlider.create(this.invoiceSliderTarget, {
      onSlide: (position, value) => { this.updateFees() },
    })

    this.updateFees()
  }

  updateFees() {
    let amount = euro(this.amountDisplay.value)

    const skontoAmount = amount.multiply(this.skontoPercentage)
    const remainingAmount = amount.subtract(skontoAmount)

    this.updateSkontoDisplay(remainingAmount, skontoAmount)
    this.updateAmountDisplay()


    this.effectiveFeeTargets.forEach((el, i) => {
      let fee = amount.multiply(feePercentages[i])
      let feeAfterSkonto = fee.multiply(1-this.skontoPercentage)
      let effectiveFee = feeAfterSkonto.subtract(skontoAmount)

      if(fee < 25) { fee = euro(25) }
      if(feeAfterSkonto < 25) { feeAfterSkonto = euro(25) }
      if(effectiveFee < 0) { effectiveFee = euro(0) }

      this.updatePaymentTermDisplay(i, effectiveFee, fee, feeAfterSkonto, skontoAmount)
    })
  }

  updateSkontoDisplay(remainingAmount, skontoAmount) {
    this.skontoPercentageTarget.innerHTML = `${this.skontoPercentageDisplay}%`
    this.remainingAmountTarget.innerHTML = `${remainingAmount.format()}€`
    this.skontoAmountTarget.innerHTML = `${skontoAmount.format()}€`
  }

  updateAmountDisplay() {
    this.amountTarget.innerHTML = `${this.amountDisplay.format()}€`
  }

  updatePaymentTermDisplay(i, effectiveFee, fee, feeAfterSkonto, skontoAmount) {
    this.effectiveFeeTargets[i].innerHTML = `${effectiveFee.format()}€`
    this.feeTargets[i].innerHTML = `${fee.format()}€`
    this.feeAfterSkontoTargets[i].innerHTML = `${feeAfterSkonto.format()}€`
  }

  selectPaymentTerm(event) {
    const element = event.target,
          container = element.closest('.card')

    // Unselect all other options
    this.buttonTargets.forEach( el => {
      el.classList.remove('btn-blue')
      el.classList.add('btn-secondary')
      el.closest('.card').classList.remove('is--active')
    })

    // Select and highlight the selected option
    element.classList.add('btn-blue')
    element.classList.remove('btn-secondary')
    container.classList.add('is--active')
  }

  get skontoPercentage() {
    return parseFloat(this.skontoSliderTarget.value / 100).toFixed(3)
  }

  get skontoAmount() {
    return this.skontoAmount
  }

  get amountDisplay() {
    return euro(this.invoiceSliderTarget.value.toString())
  }

  get skontoPercentageDisplay() {
    return this.skontoSliderTarget.value.toString().replace('.', ',')
  }
}
