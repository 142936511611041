import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["passwordField", "foo"]

  revealPassword() {
    this.passwordFieldTarget.type = "text"
    this.abortController =  new AbortController()

    document.addEventListener("mouseup", () => this.maskPassword(), { signal: this.abortController.signal })
    document.addEventListener("touchend", () => this.maskPassword(), { signal: this.abortController.signal })
  }

  maskPassword() {
    this.passwordFieldTarget.type = "password"
    this.abortController?.abort()
  }
}
