import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["icon", "content"]

  connect() {
    this.expanded = "isExpanded" in this.element.dataset
    this.updateView()
  }

  updateView() {
    if(this.expanded) {
      this.iconTarget.classList.remove("fa-plus-circle")
      this.iconTarget.classList.add("fa-minus-circle")
      this.contentTarget.classList.remove("is--hidden", "d-none")
    } else {
      this.iconTarget.classList.remove("fa-minus-circle")
      this.iconTarget.classList.add("fa-plus-circle")
      this.contentTarget.classList.add("is--hidden", "d-none")
    }
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateView()
  }
}
