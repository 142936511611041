import { Controller } from "stimulus"

const CSS_CLASSES = {
  isRequired: "is--required",
  isDisabled: "is--disabled"
}

export default class extends Controller {
  static targets = [
    "input",
    "label",
    "toggle",
    "wrapper"
  ]

  connect() {
    if (this.hasToggleTarget) { this.toggleRequirement() }
  }

  toggleRequirement() {
    this.labelTargets.forEach(element => element.classList.toggle(CSS_CLASSES.isRequired, !this.requirementIsDisabled))
    this.wrapperTargets.forEach(element => element.classList.toggle(CSS_CLASSES.isDisabled, this.requirementIsDisabled))
    this.inputTargets.forEach(element => {
      element.required = !this.requirementIsDisabled
      if (this.requirementIsDisabled) { element.value = null }
    })

  }

  get requirementIsDisabled() {
    return this.toggleTarget.checked
  }
}
