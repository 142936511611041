import { Controller } from "stimulus"
import Cleave from "cleave.js"

const CLEAVE_OPTIONS = {
  numeral: true,
  numeralPositiveOnly: true,
  numeralThousandsGroupStyle: "thousand",
  numeralDecimalMark: ",",
  numeralDecimalScale: 0,
  delimiter: ".",
  stripLeadingZeroes: true
}

export default class extends Controller {
  static targets = [
    "euroInput",
    "fxInput",
  ]

  static values = { exchangeRate: Number }

  // Lifecycle

  connect() {
    this.exchangeRate = Math.round((this.exchangeRateValue || 0) * 1000) / 1000

    this.euroCleave = new Cleave(this.euroInputTarget, CLEAVE_OPTIONS)
    this.fxCleave = new Cleave(this.fxInputTarget, CLEAVE_OPTIONS)
  }

  disconnect() {
    this.euroCleave?.destroy()
    this.fxCleave?.destroy()
  }

  // Event handlers

  handleInput(event) {
    if (event.target === this.euroInputTarget) {
      this.convertToFx()
    } else {
      this.convertToEuro()
    }
  }

  // Actions

  convertToEuro() {
    const sourceValue = this.fxCleave.getRawValue()

    if (isNaN(sourceValue)) {
      this.euroCleave.setRawValue(0)
      return
    }

    // We're manually ceiling this to ensure that Cleave won't round in the mathematically correct direction.
    this.euroCleave.setRawValue(sourceValue * this.exchangeRate)
  }

  convertToFx() {
    const sourceValue = this.euroCleave.getRawValue()

    if (isNaN(sourceValue)) {
      this.fxCleave.setRawValue(0)
      return
    }

    // We're manually flooring this to ensure that Cleave won't round in the mathematically correct direction.
    this.fxCleave.setRawValue(sourceValue / this.exchangeRate)
  }
}
