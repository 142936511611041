import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['customInput', 'staticPreview', 'select', 'reasonText']

  connect() {
    this.changeReason()
  }

  changeReason() {
    const selectedReason = this.selectTarget[this.selectTarget.selectedIndex].value
    const reasonText = this.reasonTextTargets.find(
      elem => elem.getAttribute('data-reason') == selectedReason
    ).textContent.trim()

    if(selectedReason == 'custom') {
      this.customInputTarget.classList.remove('is--hidden')
      this.staticPreviewTarget.classList.add('is--hidden')
    } else {
      this.customInputTarget.classList.add('is--hidden')
      this.staticPreviewTarget.classList.remove('is--hidden')
    }

    this.customInputTarget.innerHTML = reasonText
    this.staticPreviewTarget.innerHTML = reasonText
  }
}
