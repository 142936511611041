import { Controller } from "stimulus"

// We want to save the form's data no matter whether the Supplier gets verified or not. So we need to submit the entire form to different endpoints, depending on which button the User has clicked on. This is what we do here: we simply switch the form's effective action by including/modifying the hidden input that tells Rails what to do.
export default class extends Controller {
  setDenialFormMethod(e) {
    let methodInput = this.element.querySelector('[name="_method"]')

    // If there already exists a method-defining form <input> tag, we simply update it and are done.
    if(methodInput) {
      methodInput.value = 'delete'
      return
    }

    // If there was no previous <input> tag, we create a new one.
    methodInput = document.createElement('input')
    methodInput.type = 'hidden'
    methodInput.name = '_method'
    methodInput.value = 'delete'

    this.element.appendChild(methodInput)
  }
}
