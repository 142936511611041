import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['changeLink', 'contactPreviewSection', 'contactSection']

  showContactSection(event) {
    event.preventDefault()
    event.stopPropagation()

    this.contactPreviewSectionTarget.classList.add('is--hidden')
    this.contactSectionTarget.classList.remove('is--hidden')
  }
}
