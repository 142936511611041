import { Controller } from "stimulus"
import currency from 'currency.js'
import { euro } from '../src/currency-helpers'

let exactEuro = value => currency(value, {
  separator: '.',
  decimal: ',',
  precision: 5
})

export default class extends Controller {
  static targets = [
    'remainingAmount',
    'skontoPercentage',
    'skontoAmount'
  ]

  connect() {
    // We use currency instead of euro as we are exporting the raw numerical value from rails without any formatting. The resulting currency instance is then converted to an euro instance.
    this.amount = euro(currency(this.data.get('amount')))
    this.updateFieldsByAmount()
  }

  // Percentage was changed, so we have to re-calculate the amounts.
  updateFieldsByPercentage() {
    let skontoPercentage = this.skontoPercentage
    if(isNaN(skontoPercentage)) { return }

    // Sanity check: If the entered skontoPercentage is greater than 1 (= 100%) we reset it to 1. We don't update the view here, as the percentage is not saved in the end, only the actual skontoAmount is.
    if(skontoPercentage > 1) { skontoPercentage = 1 }

    const skontoAmount = this.amount.multiply(skontoPercentage)
    const remainingAmount = this.amount.subtract(skontoAmount)

    this.skontoAmountTarget.value = skontoAmount.format()
    this.updateRemainingAmount(remainingAmount)
  }

  // Amount was changed, so we have to re-calculate the percentage.
  updateFieldsByAmount() {
    let skontoAmount = this.skontoAmount
    if(isNaN(skontoAmount)) { return }

    // Sanity check: If the entered skontoAmount is greater than the Invoice's total gross, we reset the skontoAmount to the total gross.
    if(skontoAmount.intValue > this.amount.intValue) {
      skontoAmount = euro(this.amount)
      this.skontoAmountTarget.value = skontoAmount.format()
    }

    const skontoPercentage = euro(exactEuro(skontoAmount).divide(exactEuro(this.amount)).multiply(100))
    const remainingAmount = this.amount.subtract(skontoAmount)

    this.skontoPercentageTarget.value = skontoPercentage.format()
    this.updateRemainingAmount(remainingAmount)
  }

  updateRemainingAmount(remainingAmount) {
    this.remainingAmountTarget.innerHTML = `${remainingAmount.format()}${this.data.get('currency')}`
  }

  get skontoPercentage() {
    const formattedPercentage = this.skontoPercentageTarget.value.replace('.', '').replace(',', '.')
    const parsedPercentage = parseFloat(formattedPercentage)
    return (parsedPercentage / 100).toFixed(4)
  }

  get skontoAmount() {
    return euro(this.skontoAmountTarget.value)
  }
}
