import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    document.addEventListener('keydown', (event) => {
      const escapePressed = 'key' in event && (event.key == "Escape" || event.key == "Esc") || event.keyCode == 27

      if(escapePressed) {
        this.closeModal()
      }
    })

    document.addEventListener('click', (event) => {
      if(event.target.closest('[data-close-modal]')) {
        this.closeModal(event)
      }
      if(event.target.closest('.modal') && !event.target.closest('.modal-content')) {
        this.closeModal(event)
      }
    })
  }

  connect() {
    if(this.data.has('open')) {
      this.data.delete('open')
      this.openModal()
      return
    }

    this.element.addEventListener('click', (event) => this.openModal(event))
  }

  openModal(event) {
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.modalElement.classList.add('is--active')
  }

  closeModal(event) {
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.modalElement.classList.remove('is--active')
  }

  get modalElement() {
    if(this.data.has('target')) {
      return document.querySelector(this.data.get('target'))
    } else {
      return this.element
    }
  }
}
