import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cancelForm', 'errorMessage', 'finishForm', 'wizardForm']

  connect() {
    this.attachStyleTag()
    this.attachScriptTag()
  }

  attachStyleTag() {
    if (this.existingLinkTag) { return }

    let linkTag = document.createElement('link');
    linkTag.id = 'xs2a-stylesheet'
    linkTag.rel = 'stylesheet'
    linkTag.href = 'https://api.xs2a.com/xs2a.css'

    document.head.appendChild(linkTag);
  }

  attachScriptTag() {
    if (this.existingScriptTag) { return }

    let scriptTag = document.createElement('script');
    scriptTag.id = 'xs2a-script'
    scriptTag.onload = this.xs2aScriptLoaded.bind(this)
    scriptTag.src = 'https://api.xs2a.com/xs2a.js'

    document.head.appendChild(scriptTag);
  }

  xs2aScriptLoaded() {
    // Set to English unless we're German (default).
    if(this.locale != 'de') { window.xs2a.lang('en') }

    // Set up lifecycle callbacks.
    window.xs2a.abort(this.xs2aAbortedCallback.bind(this))
    window.xs2a.error(this.xs2aErrorCallback.bind(this))
    window.xs2a.finish(this.xs2aFinishedCallback.bind(this))

    // Start the wizard.
    window.xs2a.init()
  }

  xs2aAbortedCallback() {
    this.cancelFormTarget.submit()
  }

  xs2aErrorCallback(_errorCode, _messages, recoverable) {
    if(recoverable) { return true }

    this.errorMessageTarget.classList.remove('is--hidden')
  }

  xs2aFinishedCallback() {
    this.finishFormTarget.submit()
  }

  get existingScriptTag() { return document.querySelector('#xs2a-script') }
  get existingStyleTag() { return document.querySelector('#xs2a-stylesheet') }
  get locale() {
    if(this.data.get('locale') != 'de') {
      return 'en'
    } else {
      return 'de'
    }
  }
}
