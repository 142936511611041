import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['confirmationCheckbox', 'fileField', 'form', 'submitButton']

  connect() {
    this.fileFieldTarget.addEventListener('change', (e) => this.handleFileSelection(e))
  }

  handleFileSelection(e) {
    this.element.classList.add('is--uploading')
    this.formTarget.submit()
  }

  toggleConfirmationStatus() {
    if(this.confirmationCheckboxTarget.checked) {
      this.fileFieldTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
    } else {
      this.fileFieldTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
    }
  }
}
