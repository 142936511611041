import { Controller } from "stimulus"
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"

export default class extends Controller {
  connect() {
    this.cleanupPreviousInstances()
    const clearButton = this.clearButtonElement

    this.instance = flatpickr(this.element, {
      locale: German,
      altInput: true,
      altFormat: 'd.m.Y',
      disableMobile: true
    });

    if (this.data.has('minimum')) { this.instance.set('minDate', this.data.get('minimum')) }
    if (this.data.has('maximum')) { this.instance.set('maxDate', this.data.get('maximum')) }
    if (clearButton) { clearButton.addEventListener('click', () => this.clearDatepicker()) }
  }

  cleanupPreviousInstances() {
    const siblings  = this.element.parentNode.querySelectorAll('input[type=text]')

    for (let i = 0; i < siblings.length; i++) {
      if(siblings[i].dataset.controller) { continue }
      siblings[i].remove()
    }

    this.element.type = 'text'
  }

  clearDatepicker() {
    if (!this.instance) { return }
    this.instance.clear()
  }

  disconnect() { this.element._flatpickr.destroy() }
  get clearButtonElement() {
    try { return this.element.closest('.datepicker-wrapper').querySelector('.datepicker-clear') } catch(e) {}
  }
}
