import { Controller } from "stimulus"
import { euro } from "../src/currency-helpers"
import { DEFAULT_GROSS_AMOUNT_TO_REQUIRE_DELIVERY_NOTE } from "../config/invoice"

export default class extends Controller {
  static targets = ["changeLink", "filenameDisplay", "form", "totalGrossInput", "uploadField"]
  static values = { requiredGrossAmount: Number }

  connect() {
    this.checkGrossAmount()
  }

  checkGrossAmount() {
    const amount = euro(this.totalGrossInputTarget.value)

    if (amount >= this.requiredGrossAmount) {
      this.formTarget.classList.remove("is--hidden")
    } else {
      this.formTarget.classList.add("is--hidden")
    }
  }

  showUploadField(event) {
    event.preventDefault()
    event.stopPropagation()

    this.filenameDisplayTarget.classList.add("is--hidden")
    this.uploadFieldTarget.classList.remove("is--hidden")
  }

  // Getters

  get requiredGrossAmount() {
    if (this.requiredGrossAmountValue && this.requiredGrossAmountValue > 0) { return this.requiredGrossAmountValue }

    return DEFAULT_GROSS_AMOUNT_TO_REQUIRE_DELIVERY_NOTE
  }
}
