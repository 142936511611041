import { Controller } from "stimulus"

const ROLES_WITHOUT_COUNTRY_SELECTION = ["admin"]

export default class extends Controller {
  static targets = ["countrySelect", "kindSelect"]

  connect() { this.handleKindChange() }

  handleKindChange() {
    if (!this.hasCountrySelectTarget) { return }
    if (!this.kind) { return }

    this.countrySelectTarget.classList.toggle("d-none", ROLES_WITHOUT_COUNTRY_SELECTION.includes(this.kind))
  }

  get kind() {
    if (!this.hasKindSelectTarget) { return }

    return this.kindSelectTarget.value
  }
}
