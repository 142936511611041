import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['existingFileContainer', 'promptIcon', 'successIcon', 'uploadContainer']

  showUploadContainer(e) {
    e.preventDefault()
    e.stopPropagation()

    this.existingFileContainerTarget.classList.add('is--hidden')
    this.uploadContainerTarget.classList.remove('is--hidden')

    this.promptIconTarget.classList.remove('is--hidden')
    this.successIconTarget.classList.add('is--hidden')
  }
}
