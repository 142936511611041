import { Controller } from "stimulus"

const FALLBACK_CURRENCY = "EUR"

export default class extends Controller {
  static targets = [
    "currencySelect",
    "limitBucketSelect"
  ]

  static values = {
    initialSelection: String
  }

  connect() {
    this.updateShownOptions()
    this.selectInitialOption()
  }

  clearSelection() {
    this.limitBucketSelectTarget.querySelectorAll("option").forEach(element => element.selected = false)

    const emptyOption = this.limitBucketSelectTarget.querySelector("option:not([data-currency]")
    emptyOption.classList.remove("d-none")
    emptyOption.selected = true
  }

  updateShownOptions() {
    this.matchingOptions.forEach(element => element.classList.remove("d-none"))
    this.otherOptions.forEach(element => element.classList.add("d-none"))
    this.clearSelection()
  }

  selectInitialOption() {
    // debugger
    const option = Array.from(this.matchingOptions).find(option => option.value === this.initialSelectionValue)
    if (!option) { return }

    this.limitBucketSelectTarget.querySelectorAll("option").forEach(element => element.selected = false)
    option.selected = true
  }

  get currency() {
    if (!this.hasCurrencySelectTarget) { return FALLBACK_CURRENCY }

    return this.currencySelectTarget.value
  }

  get matchingOptions() {
    return this.limitBucketSelectTarget.querySelectorAll(`option[data-currency="${this.currency}"]`)
  }

  get otherOptions() {
    return this.limitBucketSelectTarget.querySelectorAll(`option:not([data-currency="${this.currency}"])`)
  }
}
