import { Controller } from "stimulus"
import "chart.js"

const chartStyles = {
  dashboard: {
    backgroundColor: [
      'rgba(255, 255, 255, .3)',
      '#fff'
    ],
    cutoutPercentage: 85
  },
  sidebar: {
    backgroundColor: [
      'rgba(255, 255, 255, .3)',
      '#7ed3d4'
    ],
    cutoutPercentage: 95
  }
}

export default class extends Controller {
  connect() {
    // We only want to initialize our editor if we're in the new content from the server, not if we're in the cached preview Turbolinks displays until the server's reply has arrived.
    if(document.documentElement.hasAttribute("data-turbolinks-preview")) { return }

    const ctx = this.element.getContext('2d'),
          credit_limit_used = this.data.get('used'),
          credit_limit_available = this.data.get('available'),
          style = this.data.get('style') || 'dashboard'

    let chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [credit_limit_used, credit_limit_available],
          backgroundColor: chartStyles[style].backgroundColor,
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        animation: {
          duration: 1000
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: chartStyles[style].cutoutPercentage
      }
    })
  }
}
