import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    // Sections
    "existingRecipientSection",
    "recipientFormSection",
    // Mailer method
    "mailerMethodSelection",
    // Form inputs
    "emailInput",
    "firstNameInput",
    "lastNameInput",
    "localeInput",
    // Final submit button
    "submitButton"
  ]

  connect() {
    this.checkConditions()
  }

  // We're on a legacy version of Stimulus, so we don't have access to event.params yet.
  selectExistingRecipient(event) {
    const dataset = event.currentTarget.dataset

    this.firstNameInputTarget.value = dataset.firstName
    this.lastNameInputTarget.value = dataset.lastName
    this.emailInputTarget.value = dataset.email
    this.localeInputTarget.value = dataset.locale

    this.checkConditions()
  }

  checkConditions() {
    const mailerMethod = this.mailerMethodSelectionTarget.value

    if (!mailerMethod) {
      this.existingRecipientSectionTarget.classList.add("is--disabled")
      this.recipientFormSectionTarget.classList.add("is--disabled")
      this.disableSubmitButton()
      return
    } else {
      this.existingRecipientSectionTarget.classList.remove("is--disabled")
      this.recipientFormSectionTarget.classList.remove("is--disabled")
    }

    const allFieldsFilledIn = [
      this.emailInputTarget,
      this.firstNameInputTarget,
      this.lastNameInputTarget
    ].every(field => field.checkValidity())

    if (allFieldsFilledIn) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  enableSubmitButton() { this.submitButtonTarget.classList.remove("disabled") }
  disableSubmitButton() { this.submitButtonTarget.classList.add("disabled") }
}
