import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  initialize() {
    this.checkTimeout = null
    this.checkDelay   = 2500
  }

  connect() {
    this.setCheckTimeout()
  }

  disconnect() {
    window.clearTimeout(this.checkTimeout)
  }

  setCheckTimeout() {
    // Reset the (perhaps existing) previous timeout
    window.clearTimeout(this.checkTimeout)

    // Set a new timeout
    this.checkTimeout = window.setTimeout(
      () => this.performCheck(),
      this.checkDelay
    )
  }

  performCheck() {
    axios.get(this.checkURL)
      .then( response => this.checkStatus(response.data) )
      .catch( error => this.handleError(error) )
  }

  checkStatus(status) {
    if(status == this.waitingStatus) {
      this.setCheckTimeout()
    } else {
      Turbolinks.visit(window.location)
    }
  }

  handleError(error) {
    console.log(error)
    Turbolinks.visit(this.errorRedirectURL)
  }

  get checkURL() { return this.data.get('url') }
  get errorRedirectURL() { return this.data.get('errorRedirectUrl')}
  get waitingStatus() { return this.data.get('waiting') }
}
