import { Controller } from "stimulus"

// Keep this in sync with Attachment::TITLE_CUSTOM
const TITLE_CUSTOM = "custom"

export default class extends Controller {
  static targets = ["select", "titleSection", "titleInput"]

  connect() { this.changeTitleSelection() }

  changeTitleSelection() {
    const selectedTitle = this.selectTarget[this.selectTarget.selectedIndex].value

    if(selectedTitle == TITLE_CUSTOM) {
      this.titleSectionTarget.classList.remove("is--hidden")
      this.titleInputTarget.value = ""
    } else {
      this.titleSectionTarget.classList.add("is--hidden")
      this.titleInputTarget.value = selectedTitle
    }
  }
}
