import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ['query', 'container', 'countryCodeSelect', 'suggestions', 'zipInput']

  initialize() {
    this.searchTimeout = null
    this.searchDelay = 400
    this.minLength = 3
    this.lastQuery = ''
  }

  search() {
    // Reset the (perhaps existing) previous timeout
    window.clearTimeout(this.searchTimeout)

    // Set a new timeout
    this.searchTimeout = window.setTimeout(
      () => this.submitSearch(),
      this.searchDelay
    )
  }

  submitSearch() {
    // Abort if this query is identical to the last
    if(this.lastQuery === this.searchQuery) return
    // Abort if the query is too short
    if(this.searchQuery.length < this.minLength) {
      this.clearSuggestions()
      return
    }

    // Store query and show a spinner.
    this.lastQuery = this.searchQuery
    this.containerTarget.classList.add('is--active')

    // Actually search
    axios.post(this.searchURL, {
      query: this.searchQuery,
      country_code: this.countryCode,
      zip: this.zip
    })
      .then( response => this.updateSuggestions(response.data) )
      .catch( error => this.handleSearchError(error) )
      .finally( () => this.hideSpinner() )
  }

  updateSuggestions(suggestions) { this.suggestionsTarget.innerHTML = suggestions }
  clearSuggestions() { this.suggestionsTarget.innerHTML = '' }
  handleSearchError(error) { console.log(error) }
  hideSpinner() { this.containerTarget.classList.remove('is--active') }

  changeCountry() {
    this.clearSuggestions()
    this.queryTarget.value = ''
    this.lastQuery = ''
  }

  get searchURL() { return this.data.get('url') }
  get searchQuery() { return this.queryTarget.value; }
  get countryCode() { return this.countryCodeSelectTarget.value; }

  get zip() {
    if (this.hasZipInputTarget) {
      return this.zipInputTarget.value
    } else {
      return null
    }
  }
}
