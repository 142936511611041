import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['option', 'selectField']

  connect() {}

  selectKind(e) {
    this.optionTargets.forEach( option => {
      if(option.dataset.kind == this.currentKind) {
        option.classList.remove('is--hidden')
      } else {
        option.classList.add('is--hidden')
      }
    })
  }

  get currentKind() { return this.selectFieldTarget.value }
}
