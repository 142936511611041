import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['radio', 'button', 'submit', 'termsCheckBox']

  selectPaymentTerm(event) {
    const element = event.target,
          container = element.closest('.card'),
          radioButton = container.querySelectorAll('input[type=radio]')[0]

    // Unselect all other options
    this.radioTargets.forEach( el => el.checked = false )
    this.buttonTargets.forEach( el => {
      el.classList.remove('btn-blue')
      el.classList.add('btn-secondary')
      el.closest('.card').classList.remove('is--active')
    })

    // Select and highlight the selected option
    radioButton.checked = true
    element.classList.add('btn-blue')
    element.classList.remove('btn-secondary')
    container.classList.add('is--active')

    // Update the submit button, if possible
    this.updateSubmitButtonState()
  }

  acceptTerms() {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState() {
    const isTermSelected = this.radioTargets.reduce(this.termSelectionReducer, false)
    const isTermsAccepted = this.termsCheckBoxTarget.checked

    this.submitTarget.disabled = !(isTermSelected && isTermsAccepted)
  }

  termSelectionReducer(status, input) {
    return status || input.checked
  }
}
