import { Controller } from "stimulus"
import { JsonTree } from "../src/json-tree-view"

export default class extends Controller {
  static values = { data: Object }

  connect() {
    this.tree = JsonTree.create(this.dataValue, this.element);
    this.tree.expand()
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
