import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = ['fileField', 'form']

  connect() {
    this.fileFieldTarget.addEventListener('change', () => this.handleFileSelection())
    this.formTarget.addEventListener('ajax:complete', () => this.handleUploadComplete())
  }

  handleUploadComplete() {
    this.element.classList.remove('is--uploading')
  }

  handleFileSelection() {
    // With older IE versions, we might land here once again after resetting the field value.
    if(this.fileFieldTarget.value == null || this.fileFieldTarget.value == '') { return }

    this.element.classList.add('is--uploading')
    Rails.fire(this.formTarget, 'submit')

    // We need to reset the file field after submittal, as a failed upload will keep the selected file and thus not trigger a change event when the same file is selected again.
    this.fileFieldTarget.value = null
  }
}
