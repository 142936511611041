import { Controller } from "stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  connect() {
    let options = {
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalMark: ',',
      delimiter: '.',
      stripLeadingZeroes: true
    }

    if("integerOnly" in this.element.dataset) { options.numeralDecimalScale = 0 }

    this.cleave = new Cleave(this.element, options)
  }

  disconnect() {
    this.cleave?.destroy()
  }
}
