import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  connect() {
    if (document.documentElement.hasAttribute("data-turbolinks-preview")) { return }

    axios.post(this.element.dataset.url)
      .then(() => this.element.remove())
  }
}
