/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//console.log('Hello World from Webpacker')

import "../src/polyfills"
import Rails from "rails-ujs"
import Turbolinks from "turbolinks"
import TomSelect from "tom-select"
import setupCSRFToken from "../src/csrf-token"
import "bootstrap"
import "cookieconsent"

// Stimulus controller initialization
import "../controllers"


// Rails UJS and Turbolinks initialization
Rails.start()
Turbolinks.start()

// By default, Turbolinks issues requests for anchor-only links, which is stupid. So we fix it to only update the internal representation.
document.addEventListener("turbolinks:click", function (event) {
  var anchorElement = event.target
  var isSamePageAnchor = (
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname
  )

  if (isSamePageAnchor) {
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
      event.data.url,
      Turbolinks.uuid()
    )
    event.preventDefault()
  }
})

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".tom-select").forEach(element => {
    new TomSelect(element, {
      plugins: ["remove_button"],
      sortField: {
        field: "text",
        direction: "asc"
      }
    })
  })
})

// CSRF setup for axios
setupCSRFToken()
